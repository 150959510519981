

































































import { Component, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const UserStore = namespace('UserStore')
import { UserModel } from '@/core/models/UserModel'
import FormGroup from '@/components/forms/FormGroup.vue'
import Field from '@/components/forms/Field.vue'
import { required } from 'vuelidate/lib/validators'
import BaseSelectNew from '@/components/forms/BaseSelectNew/BaseSelect.vue'
import { DepositInvoiceModel } from '@/core/models/InvoicesModel'
import { TippyComponent } from 'vue-tippy/dist/vue-tippy.esm'
import { BreakpointsMixin } from '@/mixins/breakpoints.mixin'

interface PaymentMethod {
  id: number
  name: string
  disabled: boolean
  tooltip: string
}
const validations = {
  paymentMethod: { required },
  amount: { required },
}

@Component({
  name: 'DepositInvoiceForm',
  components: {
    BaseSelectNew,
    FormGroup,
    Field,
    TippyComponent,
  },
  validations,
})
export default class DepositInvoiceForm extends BreakpointsMixin {
  @Prop({ required: true })
  value!: DepositInvoiceModel

  @Watch('value')
  onValueChanged(val: DepositInvoiceModel): void {
    this.updateModel(val)
  }

  @UserStore.Getter
  public currentUser!: UserModel

  get paymentMethodList(): PaymentMethod[] {
    return [
      {
        id: 1,
        name: 'Оплата картой',
        disabled: false,
        tooltip: '',
      },
      {
        id: 2,
        name: 'Оплата по счету',
        disabled: !this.currentUser.invoice,
        tooltip: !this.currentUser.invoice ? 'invoice' : '',
      },
    ]
  }

  paymentMethod: null | number = 1
  amount: null | number = null

  created(): void {
    if (this.value.amount > 0) {
      this.amount = this.value.amount
    }
  }

  public updateModel(data: DepositInvoiceModel): void {
    this.paymentMethod = data?.payment_method ?? 1
    this.amount = data?.amount ?? null
  }

  public update(): void {
    this.$emit('input', {
      payment_method: this.paymentMethod,
      amount: this.amount,
    })
  }

  public checkValidity(): boolean {
    this.$v.$touch()

    return !this.$v.$anyError
  }
}
