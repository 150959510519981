export class DepositInvoiceModel {
  payment_method = 1
  amount = 0
}

export class PaymentResponseModel {
  payment_link = ''
  download: number | null = null
}

export class InvoiceItemModel {
  name = ''
  additional = ''
  price = 0
  quantity = 0
  total = 0
}

export class InvoiceModel {
  id = 0
  created_at = ''
  sum = 0
  paid = false
  canceled = false
  download = false
  cart: InvoiceItemModel[] = []
}
