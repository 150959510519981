
















































import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import Popup from '@/components/Popup.vue'
import Modal from '@/components/Modal.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import DepositInvoiceForm from './DepositInvoiceForm.vue'
import { DepositInvoiceModel } from '@/core/models/InvoicesModel'
import InvoicesService from '@/core/services/InvoicesService'

@Component({
  name: 'DepositInvoice',
  components: {
    Popup,
    Modal,
    BaseButton,
    DepositInvoiceForm,
  },
})
export default class DepositInvoice extends Vue {
  @Prop({ default: true })
  show!: boolean

  @Prop({ default: 0 })
  amount!: number

  @Ref() readonly depositInvoiceForm!: DepositInvoiceForm
  @Ref() readonly download!: HTMLAnchorElement

  showDepositInvoiceForm = true
  showErrorModal = false
  errorMessage = ''
  model = new DepositInvoiceModel()
  saveDisabled = false

  created(): void {
    if (this.amount > 0) {
      this.model.amount = this.amount
    }
  }

  async submit(): Promise<void> {
    if (this.depositInvoiceForm.checkValidity()) {
      this.saveDisabled = true
      try {
        const response = await InvoicesService.deposit(this.model)
        if (response?.download) {
          await this.downloadInvoice(response?.download)
        }
        if (response?.payment_link) {
          this.redirect(response.payment_link)
        } else {
          this.errorMessage = 'Ссылка не найдена'
          this.showErrorModal = true
        }
      } catch (error) {
        if (InvoicesService.isServiceError(error)) {
          this.errorMessage = error.response?.data?.message || error.message
        }
        this.errorMessage = this.errorMessage || 'Неизвестная ошибка'
        this.showErrorModal = true
      }
      this.showDepositInvoiceForm = false
      this.saveDisabled = false
    }
  }

  redirect(url: string): void {
    if (typeof url === 'string' && url.startsWith('http')) {
      window.location.href = url
    } else {
      this.$router.push(url)
    }
  }

  async downloadInvoice(id: number): Promise<void> {
    try {
      const blob = await InvoicesService.downloadInvoice(id)
      const url = window.URL.createObjectURL(blob)
      this.download.href = url
      this.download.click()
    } catch (error) {
      console.log(error)
      this.errorHandler(error)
    }
  }

  errorHandler(error: unknown): void {
    this.errorMessage = ''
    if (InvoicesService.isServiceError(error)) {
      const errors = error.response?.data?.errors || []
      this.errorMessage = errors[0] || error.message
    }
    this.errorMessage = this.errorMessage || 'Неизвестная ошибка'
    this.showErrorModal = true
  }
}
